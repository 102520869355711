/* eslint-disable @typescript-eslint/no-explicit-any */
import { QueryInput } from '@aws-sdk/client-dynamodb';
import { useDynamoDB } from 'contexts';
import { useAppConfig } from 'contexts/AppConfigProvider';
import { format, formatDistance } from 'date-fns';
import { loopQuery } from 'hooks/utils';
import isNil from 'lodash.isnil';
import { useState } from 'react';
import {
  ELogLevels,
  ESystemLogStatus,
  IExpressionValues,
  IOptions,
  LogDetails,
  LogDto,
  LogEntity,
} from 'shared/interfaces';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const AWS_DYNAMODB_TIME_COEFFICIENT = 0.1;

/**
 * Returns the date in full day format 'MM-dd HH:mm'.
 *
 * @param {Date} date The day.
 * @returns {string} The formatted date string.
 */
export const formatDateInFullDayTime = (date: Date): string => {
  return format(date, 'MM-dd HH:mm');
};

type GetLogsForSystemQueryProps = {
  systemUid: string;
  timeStart: number;
  timeEnd: number;
  tableName: string;
  options?: ILogDetailQueryOptions;
};

export interface ILogDetailQueryOptions {
  selectedLevels?: IOptions[];
  selectedModules?: IOptions[];
  limit?: number;
}
interface IGetSystemLogDetails {
  data: Map<string, LogDetails>;
  loading: boolean;
  error: Optional<Error>;
}
/**
 * Get timestamp of the latest log for each system from DynamoDB
 *
 * @param {string[]} systemUids System UID for query
 * @param {Date} startDate Start time for logs (midnight on selected day)
 * @param {Date} endDate End time for logs (just before midnight on selected day)
 * @param {ILogDetailQueryOptions} options Optional filters for levels, modules, and limits
 * @returns {Object} detailed log data, and any errors
 */
export function useGetSystemLogDetails(
  systemUids: string[],
  startDate: Date,
  endDate: Date,
  options?: ILogDetailQueryOptions
): IGetSystemLogDetails {
  const { appConfig } = useAppConfig();
  const [data, setLogData] = useState<Map<string, LogDetails>>(new Map());
  const [error, setErrorDynamoDB] = useState<Optional<Error>>(undefined);
  const [loading, setLoading] = useState(false);
  const { dynamoDBClient } = useDynamoDB();

  useDeepCompareEffect(() => {
    const isDateRangeValid = startDate < endDate;
    if (!isDateRangeValid || systemUids.length === 0 || isNil(dynamoDBClient)) {
      return;
    }

    const fetchSystemLogs = async () => {
      const timeString = formatDistance(endDate, startDate);
      const timeStart: number = startDate.getTime();
      const timeEnd: number = endDate.getTime();
      const promiseList = systemUids.map((systemUid) => {
        const query: QueryInput = createGetLogsForSystemQuery({
          systemUid,
          timeStart,
          timeEnd,
          tableName: appConfig.aws.dynamoDb.logsTableName,
          options,
        });
        return loopQuery<LogEntity>(query, dynamoDBClient).then((logs) => {
          return { systemUid, logs };
        });
      });

      try {
        const responseList = await Promise.all(promiseList);
        console.log(
          `[useLogDetails] ${endDate.toLocaleTimeString()} - End Querying logs for ${responseList.length} systems`
        );
        const responseMap = responseList.reduce(
          (systemUidToLogs, { systemUid, logs }) => {
            systemUidToLogs.set(
              systemUid,
              convertLogEntitiesToLogDetails(logs, timeString)
            );
            return systemUidToLogs;
          },
          new Map<string, LogDetails>()
        );
        setLogData(responseMap);
      } catch (error: any) {
        setErrorDynamoDB(error);
      } finally {
        setLoading(false);
      }
    };
    console.log(
      `[useLogDetails] ${endDate.toLocaleTimeString()} - Start Querying logs for ${systemUids.length} systems`
    );
    setLoading(true);
    fetchSystemLogs();
  }, [systemUids, startDate, endDate, options, dynamoDBClient]);

  return {
    data,
    loading,
    error,
  };
}
/**
 * Converts an array of log data entity objects to an array of log data DTO objects.
 *
 * @param {LogEntity[]} logEntities - The array of log data entity objects to convert.
 * @param {string} timeString - The time string for the log data.
 * @returns {LogDetails} The converted array of log data DTO objects.
 */
function convertLogEntitiesToLogDetails(
  logEntities: LogEntity[],
  timeString: string
): LogDetails {
  const logDtos: LogDto[] = logEntities.map((log) =>
    convertLogDataToLogDto(log)
  );
  const logInfo = convertLogDtoToLogInfo(logDtos, timeString);
  return {
    logs: logDtos,
    ...logInfo,
  } as LogDetails;
}

/**
 * Creates a query to retrieve logs for a specific system within a given time range.
 *
 * @param {Object} options - The options for the query.
 * @param {string} options.systemUid - The UID of the system.
 * @param {number} options.timeStart - The start time of the logs in milliseconds.
 * @param {number} options.timeEnd - The end time of the logs in milliseconds.
 * @param {Object} [options.options] - The optional filter options for the logs.
 * @param {Array<string>} [options.options.selectedLevels] - The selected log levels.
 * @param {Array<string>} [options.options.selectedModules] - The selected log modules.
 * @param {number} [options.options.limit] - The maximum number of logs to retrieve.
 * @returns {QueryInput} The query input object for retrieving the logs.
 */
function createGetLogsForSystemQuery({
  systemUid,
  timeStart,
  timeEnd,
  tableName,
  options: filterOptions,
}: GetLogsForSystemQueryProps): QueryInput {
  const query: QueryInput = {
    ExpressionAttributeValues: {
      ':s': { S: `${systemUid}` },
      ':tss': { N: `${timeStart * AWS_DYNAMODB_TIME_COEFFICIENT}` },
      ':tse': { N: `${timeEnd * AWS_DYNAMODB_TIME_COEFFICIENT}` },
    },
    ExpressionAttributeNames: {
      '#ts': 'timestamp',
      '#lvl': 'level',
      '#mod': 'module',
    },
    KeyConditionExpression: 'systemUid = :s AND #ts between :tss and :tse',
    ProjectionExpression: '#lvl, #ts, message, #mod',
    TableName: tableName,
    ScanIndexForward: false,
  };

  const expressionValues = { ...query.ExpressionAttributeValues };
  const filterExpressionList: string[] = [];
  // Build filter expression for level.
  let levelFilterString: Optional<string> = undefined;
  if (filterOptions?.selectedLevels) {
    const levelFilter: string[] = [];
    const { selectedLevels } = filterOptions;
    const levelList = selectedLevels?.map((option) => option.label[0]);
    levelList?.forEach((option) => {
      const key = `:lvl${option}` as keyof IExpressionValues;
      expressionValues[key] = { N: option };
      levelFilter.push(`#lvl = ${key}`);
    });
    levelFilterString = '(' + levelFilter.join(' OR ') + ')';
    if (levelList.length > 0) {
      filterExpressionList.push(levelFilterString);
    }
  }
  // Build filter expression for module.
  let moduleFilterString: Optional<string> = undefined;
  if (filterOptions?.selectedModules) {
    const moduleFilter: string[] = [];
    const { selectedModules } = filterOptions;
    const moduleList = selectedModules?.map((option) => option.label);
    moduleList?.forEach((module) => {
      const key = `:mod${module}` as keyof IExpressionValues;
      expressionValues[key] = { S: module };
      moduleFilter.push(`#mod = ${key}`);
    });
    moduleFilterString = '(' + moduleFilter.join(' OR ') + ')';
    if (moduleFilter.length > 0) {
      filterExpressionList.push(moduleFilterString);
    }
  }

  query.ExpressionAttributeValues = expressionValues;

  query.FilterExpression =
    filterExpressionList.length > 0
      ? filterExpressionList.join(' AND ')
      : undefined;

  query.Limit =
    filterOptions && filterOptions.limit ? filterOptions.limit : undefined;

  return query;
}
/**
 * Converts an ILogData object to a LogDto object.
 *
 * @param {LogEntity} logData - The ILogData object to convert.
 * @returns {LogDto} The converted LogDto object.
 */
export function convertLogDataToLogDto(logData: LogEntity): LogDto {
  return {
    timestamp: new Date(logData.timestamp / AWS_DYNAMODB_TIME_COEFFICIENT),
    level: logData.level,
    module: logData.module,
    message: logData.message,
  };
}

/**
 * Calculates the log level and system status based on the given log list and time string.
 *
 * @param {LogDto[]} logs - The list of log data.
 * @param {string} timeString - The time string.
 * @returns {{reasonList: string[], logLevel: ELogLevels, systemLogStatus: ESystemLogStatus}}
 *          The calculated log level, system log status, and a list of reasons.
 */
function convertLogDtoToLogInfo(
  logs: LogDto[],
  timeString: string
): {
  reasonList: string[];
  logLevel: ELogLevels;
  systemLogStatus: ESystemLogStatus;
} {
  let logLevel = ELogLevels.INFO;
  let systemLogStatus = ESystemLogStatus.NOMINAL;
  const reasonList: string[] = [];

  if (logs.length === 0) {
    reasonList.push(`No logs for last ${timeString}`);
    logLevel = ELogLevels.ERROR_NO_LOGS;
    systemLogStatus = ESystemLogStatus.ERROR_NO_LOGS;
    return {
      reasonList,
      logLevel,
      systemLogStatus,
    };
  }
  const levelCounts = logs.reduce(
    (acc, { level }) => {
      if (level in acc) {
        acc[level as ELogLevels] += 1;
      } else {
        acc[level as ELogLevels] = 1;
      }
      return acc;
    },
    {
      [ELogLevels.INFO]: 0,
      [ELogLevels.DEBUG]: 0,
      [ELogLevels.WARNING]: 0,
      [ELogLevels.ERROR]: 0,
      [ELogLevels.ERROR_NO_LOGS]: 0,
    }
  );
  if (levelCounts[ELogLevels.WARNING] > 0) {
    reasonList.push(
      `Logs have ${
        levelCounts[ELogLevels.WARNING]
      } warnings in last ${timeString}`
    );
    logLevel = ELogLevels.WARNING;
    systemLogStatus = ESystemLogStatus.WARNING;
  } else if (levelCounts[ELogLevels.ERROR] > 0) {
    reasonList.push(
      `Logs have ${levelCounts[ELogLevels.ERROR]} errors in last ${timeString}`
    );
    logLevel = ELogLevels.ERROR;
    systemLogStatus = ESystemLogStatus.ERROR;
  }
  return {
    reasonList,
    logLevel,
    systemLogStatus,
  };
}

/**
 * Creates a query to retrieve the timestamp of the last log entry for a specific system.
 *
 * @param {string} systemUid - The UID of the system.
 * @returns {Object} The query input object for retrieving the last log timestamp.
 */
function createGetSystemLastLogTimestampQuery(
  systemUid: string,
  tableName: string
) {
  return {
    ExpressionAttributeValues: {
      ':systemUid': { S: systemUid },
    },
    ExpressionAttributeNames: { '#timestamp': 'timestamp' },
    KeyConditionExpression: 'systemUid = :systemUid',
    ProjectionExpression: 'systemUid, #timestamp',
    TableName: tableName,
    ScanIndexForward: false,
    Limit: 1,
  };
}

export type GetSystemUidToLastTimestampReturn = {
  data: Map<string, Date>;
  error: Optional<Error>;
  loading: boolean;
};
/**
 * Retrieves the last timestamp for each system in the given list of system UIDs.
 *
 * @param {string[]} systemUids - The list of system UIDs to retrieve timestamps for.
 * @returns {Promise<GetSystemUidToLastTimestampReturn>} An object containing the retrieved timestamps,
 * error information, and a loading flag.
 */
export function useGetSystemUidToLastTimestamp(
  systemUids: string[]
): GetSystemUidToLastTimestampReturn {
  const { appConfig } = useAppConfig();
  const [data, setData] = useState<Map<string, Date>>(new Map());
  const [error, setError] = useState<Optional<Error>>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const { dynamoDBClient } = useDynamoDB();
  useDeepCompareEffect(() => {
    if (systemUids.length === 0 || isNil(dynamoDBClient)) {
      return;
    }
    console.log(
      `[useGetSystemUidToLastTimestamp] systemUids.length: ${systemUids.length}`
    );
    const fetchLatestSystemTimestamps = async () => {
      const promiseList = systemUids.map((systemUid) =>
        dynamoDBClient
          .query(
            createGetSystemLastLogTimestampQuery(
              systemUid,
              appConfig.aws.dynamoDb.logsTableName
            )
          )
          .then((response) => {
            return { systemUid, response };
          })
      );
      try {
        const systemsLastTimestamp = await Promise.all(promiseList);
        const responseMap = systemsLastTimestamp.reduce(
          (acc, { systemUid, response: response }) => {
            if (response.Items && response.Items[0]) {
              acc.set(
                systemUid,
                new Date(
                  Number(response.Items[0].timestamp.N) /
                    AWS_DYNAMODB_TIME_COEFFICIENT
                )
              );
            }
            return acc;
          },
          new Map<string, Date>()
        );
        setData(responseMap);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    setLoading(true);
    fetchLatestSystemTimestamps();
  }, [systemUids, dynamoDBClient]);

  return { data, error, loading };
}
