export enum Environment {
  DEV = 'dev',
  DEVSTABLE = 'devstable',
  SANDBOX = 'sandbox',
  SQA = 'sqa',
  STAGING = 'staging',
  PROD = 'prod',
}

export interface AppConfig {
  aws: AwsConfig;
  environment: Environment;
  dashboardBaseUrl: string;
  hasura: HasuraConfig;
}

export enum AwsRegion {
  US_WEST_2 = 'us-west-2',
}

export interface AwsCognitoConfig {
  identityPoolId: string;
  userPoolId: string;
  webClientId: string;
}

export interface AwsDynamoDbConfig {
  region: AwsRegion;
  logsTableName: string;
}

export interface AwsConfig {
  cognito: AwsCognitoConfig;
  dynamoDb: AwsDynamoDbConfig;
}

export interface HasuraConfig {
  graphqlEndpoint: URL;
}
