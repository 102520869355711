import {
  AppConfig,
  AwsCognitoConfig,
  AwsConfig,
  AwsDynamoDbConfig,
  AwsRegion,
  Environment,
  HasuraConfig,
} from './AppConfig';
import { getEnumValue, getString, getUrl } from './config-utils';

/**
 * Create an AppConfig object from an object of config values
 */
export function createAppConfig(values: Record<string, string>): AppConfig {
  const appConfig: AppConfig = {
    aws: createAwsConfig(values),
    dashboardBaseUrl: getString(values, 'DASHBOARD_BASE_URL'),
    environment: getEnumValue(values, 'ENV', Environment),
    hasura: createHasuraConfig(values),
  };

  return appConfig;
}

function createAwsConfig(values: Record<string, string>): AwsConfig {
  const awsConfig: AwsConfig = {
    cognito: createAwsCognitoConfig(values),
    dynamoDb: createAwsDynamoDbConfig(values),
  };

  return awsConfig;
}

function createAwsCognitoConfig(
  values: Record<string, string>
): AwsCognitoConfig {
  const awsCognitoConfig: AwsCognitoConfig = {
    identityPoolId: getString(values, 'AWS_COGNITO_IDENTITY_POOL_ID'),
    userPoolId: getString(values, 'AWS_COGNITO_USER_POOL_ID'),
    webClientId: getString(values, 'AWS_COGNITO_WEB_CLIENT_ID'),
  };
  return awsCognitoConfig;
}

function createAwsDynamoDbConfig(
  values: Record<string, string>
): AwsDynamoDbConfig {
  const awsDynamoDbConfig: AwsDynamoDbConfig = {
    region: getEnumValue(values, 'AWS_DYNAMODB_REGION', AwsRegion),
    logsTableName: getString(values, 'AWS_DYNAMODB_LOGS_TABLE_NAME'),
  };
  return awsDynamoDbConfig;
}

function createHasuraConfig(values: Record<string, string>): HasuraConfig {
  const hasuraConfig: HasuraConfig = {
    graphqlEndpoint: getUrl(values, 'HASURA_GRAPHQL_ENDPOINT'),
  };

  return hasuraConfig;
}
